<template>
  <ul class="help__links wrapper-base line-mega line_mobile-global">
    <li
        v-for="(file, i) of files"
        :key="i"
    >
      <a
          class="help__links-item"
          :href="`${config.api.dataforce}/help/${file.name}.pdf`"
          target="_blank"
      >
        {{ file.title }}
      </a>
    </li>
  </ul>
</template>

<script>
import ROUTE_NAME from '@/router/routeName'

export default {
  name: 'help',
  metaInfo() {
    return {
      title: 'Пользовательская документация и описание функциональных характеристик | DATAFORCE',
      meta: [
        {
          name: 'description',
          content: 'Подробная информация о тарифах, пользовательская документация и описание функциональных характеристик ПО DATAFORCE'
        }
      ]
    }
  },
  data () {
    return {
      ROUTE_NAME,
      files: [
        {
          title: 'Описание функциональных характеристик ПО DATAFORCE',
          name: 'description_app_df'
        },
        {
          title: 'Пользовательская документация DATAFORCE',
          name: 'user_manual_app_df'
        }
      ]
    }
  },

  computed: {
    config () {
      return this.$store.state.config
    }
  }
}
</script>

<style
    lang="scss"
    scoped
>
  .help__links {
		height: 100%;
		min-height: 40vh;
    /* height: calc(100vh - 154px);
    padding-top: 200px; */

    li {
      position: relative;
      margin-bottom: 24px;
      padding-left: 20px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 4px);
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #BDBDBD;
      }
    }

    &-item {
      color: #4158D9;
      font-size: 16px;

      &:hover {
        color: #2979FF;
        text-decoration: underline;
      }
    }
  }
</style>
